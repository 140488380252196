.noti-icon .badge {
  left: 23px;
}
.mt-2 {
  margin-top: 20px !important;
}

.dataTables_filter,
.dataTables_paginate {
  float: right;
}

.rdw-editor-main {
  border: 1px solid #eeeef5;
  height: 239px;
}
.dz-message {
  text-align: center;
  padding: 100px;
}
.fcYNFc {
  background-color: #ffffff !important;
}
.task-box {
  border: 1px solid #e1e1e6;
}
.react-datepicker-wrapper {
  width: 100% !important;
}

.ReactModal__Overlay {
  z-index: 1001 !important;
}

.fc-event .fc-content {
  padding: 5px;
  color: #fff;
}
.chat-conversation .right .conversation-list {
  margin-right: 15px;
}

.external-event {
  &:hover {
    cursor: pointer;
  }
}

.border-0 {
  border: 0px !important;
}

.form-control:disabled,
.form-control[readonly] {
  background-color: rgb(223, 216, 224) !important;
}

.loader {
  margin-top: 10%;
  margin-left: 30%;
  width: 5% !important;
  height: 10%;
  /* border-radius: calc(0.25rem - 1px) !important; */
  /* background-color: #000000; */
  z-index: 5000;
}

.features-border-bottom {
  border-bottom: 1px solid rgb(166, 193, 250);
}

//order Success page scss
.box {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
}

.alert {
  margin-top: 25px;
  // background-color:#fff;
  font-size: 14px;
  font-family: sans-serif;
  text-align: center;
  width: 300px;
  // height:100px;
  padding-top: 150px;
  position: relative;
  // border: 1px solid #efefda;
  // border-radius: 2%;
  // box-shadow:0px 0px 3px 1px #ccc;
}

.alert::before {
  width: 100px;
  height: 100px;
  position: absolute;
  border-radius: 100%;
  inset: 20px 0px 0px 100px;
  font-size: 60px;
  line-height: 100px;
  border: 5px solid gray;
  animation-name: reveal;
  animation-duration: 1.5s;
  animation-timing-function: ease-in-out;
}

.alert > .alert-body {
  opacity: 0;
  animation-name: reveal-message;
  animation-duration: 1s;
  animation-timing-function: ease-out;
  animation-delay: 1.5s;
  animation-fill-mode: forwards;
}

@keyframes reveal-message {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.success {
  color: green;
}

.success::before {
  content: "✓";
  background-color: #eff;
  box-shadow: 0px 0px 12px 7px rgba(200, 255, 150, 0.8) inset;
  border: 5px solid green;
}

@keyframes reveal {
  0% {
    border: 5px solid transparent;
    color: transparent;
    box-shadow: 0px 0px 12px 7px rgba(255, 250, 250, 0.8) inset;
    transform: rotate(1000deg);
  }
  25% {
    border-top: 5px solid gray;
    color: transparent;
    box-shadow: 0px 0px 17px 10px rgba(255, 250, 250, 0.8) inset;
  }
  50% {
    border-right: 5px solid gray;
    border-left: 5px solid gray;
    color: transparent;
    box-shadow: 0px 0px 17px 10px rgba(200, 200, 200, 0.8) inset;
  }
  75% {
    border-bottom: 5px solid gray;
    color: gray;
    box-shadow: 0px 0px 12px 7px rgba(200, 200, 200, 0.8) inset;
  }
  100% {
    border: 5px solid gray;
    box-shadow: 0px 0px 12px 7px rgba(200, 200, 200, 0.8) inset;
  }
}

// searchBar

.jwfbbd {
  margin-top: 13px;
}
.app-search {
  margin-left: 100px;
}
#suggestProduct {
  position: absolute;
  display: none;
  outline: 0;
  border-radius: 5;
  z-index: -1;
}
// .search-item-desing{
//   // background: lightblue;
//   // margin-top:160px;
//   // transform: translateY(160px);
//   margin-top:-20rem;
//   padding-top:10px;

// }

// .search-item-desing li{
//     list-style: none;
//     background-color: white;

// }

.search-box {
  border-radius: 20px;
  transition: 0.5s all linear;
  width: 100%;
}
.search-box:focus-within {
  width: 400px;
  // border: 1px solid rgb(117, 196, 199);
  // transition: 1s all ease-out;
}
.search_img {
  height: 50px;
  width: 50px;
}

// .search-a{
//     font-size:120%

// }

// .search-li{
//     z-index: 30;
//     // margin-bottom: 1%;

// }

// .search-li:hover{
//     cursor: pointer;
// }

//new search

#list {
  list-style: none;
  margin-top: 14%;
  margin-left: 52%;
  width: 360px;
  padding: 0;
  padding-bottom: 1.5em;
  background-color: white;
  border-top: 1px solid lightgray;
  max-height: 0;
  overflow: hidden;
  overflow-y: auto;
  animation: enlarge 0.25s forwards;
  li {
    margin-right: 0em;
    margin-left: 0em;
    padding: 0em;
    line-height: 2;
    cursor: pointer;
    &:hover {
      background: whitesmoke;
    }
  }
}

.header-logo {
  height: 30px;
}

.page-content-productDetails {
  margin-top: 10px !important;
}

.page-title-box h4 {
  font-size: 30px !important;
  color: #0da9ef !important;
}

.content-spinner {
  margin: 20% 45%;
  width: 150px;
  height: 150px;
}

.footer-p {
  margin-bottom: 0rem;
}

@keyframes enlarge {
  to {
    max-height: 30em;
  }
}

.brand-img {
  object-fit: contain;
  width: 140px;
  height: 80px;
}
// .modal-open .container-fluid, .modal-open  .container {
//   -webkit-filter: blur(1px);
//   -moz-filter: blur(1px);
//   -o-filter: blur(1px);
//     -ms-filter: blur(1px);
//     filter: blur(1px);
//     background-color: #01223770;
//     opacity:.5 !important;
// }
.modal-backdrop {
  backdrop-filter: blur(5px);
  background-color: #070808c2;
}
.modal-backdrop.in {
  filter: blur(2.5);
  opacity: 1 !important;
}
// .modal:before{
//   opacity:.001 !important;
// }
.card-expand {
  transition: 0.5s ease-in-out;
}
.card-expand:hover {
  transform: scale(1.1);
}

.cartPopUp {
  height: 520px;
  width: 560px;
}

.home-header {
  font-size: 30px !important;
  color: #0da9ef !important;
}

@media (min-width: 768px) and (max-width: 992px) {
  .home-header {
    font-size: 30px !important;
    color: #0da9ef !important;
  }
  .page-title-box h4 {
    font-size: 30px !important;
    color: #0da9ef !important;
  }
  .cartPopUp {
    height: 480px;
    width: 560px;
  }
  .header-logo {
    height: 25px;
  }
  .brand-img {
    object-fit: contain;
    width: 150px;
    height: 120px;
  }
  .app-search {
    margin-left: 0px;
  }
  .content-spinner {
    margin: 40% 45%;
    width: 100px;
    height: 100px;
  }
  #suggestProduct {
    position: absolute;
    display: none;
    outline: 0;
    border-radius: 5;
    z-index: -1;
  }

  #list {
    list-style: none;
    margin-top: 14%;
    margin-left: 65%;
    width: 360px;
    padding-bottom: 1.5em;
    background-color: white;
    border-top: 1px solid lightgray;
    max-height: 0;
    overflow: hidden;
    overflow-y: auto;
    animation: enlarge 0.25s forwards;
    li {
      margin-right: 0em;
      margin-left: 0em;
      padding: 0em;
      line-height: 2;
      cursor: pointer;
      &:hover {
        background: whitesmoke;
      }
    }
  }

  @keyframes enlarge {
    to {
      max-height: 30em;
    }
  }
}

@media (min-width: 576px) and (max-width: 767px) {
  .home-header {
    font-size: 25px !important;
    color: #0da9ef !important;
  }
  .page-title-box h4 {
    font-size: 25px !important;
    color: #0da9ef !important;
  }
  .header-logo {
    height: 25px;
  }
  .cartPopUp {
    height: 480px;
    width: 560px;
  }
  .brand-img {
    object-fit: contain;
    width: 120px;
    height: 80px;
  }
  .content-spinner {
    margin: 50% 45%;
    width: 80px;
    height: 80px;
  }
  #list {
    list-style: none;
    margin-top: 8.6rem;
    margin-left: 5rem;
    width: 25rem;
    padding: 0;
    padding-bottom: 1.5em;
    background-color: white;
    border-top: 1px solid lightgray;
    max-height: 0;
    overflow: hidden;
    overflow-y: auto;
    animation: enlarge 0.25s forwards;
    li {
      margin-right: 0em;
      margin-left: 0em;
      padding: 0em;
      line-height: 2;
      cursor: pointer;
      &:hover {
        background: whitesmoke;
      }
    }
  }

  @keyframes enlarge {
    to {
      max-height: 30em;
    }
  }
}

@media (min-width: 0px) and (max-width: 575px) {
  .home-header {
    font-size: 18px !important;
    color: #0da9ef !important;
  }
  .page-title-box h4 {
    font-size: 18px !important;
    color: #0da9ef !important;
  }
  .cartPopUp {
    height: 480px;
    width: 350px;
  }
  .header-logo {
    height: 25px;
  }
  .brand-img {
    object-fit: contain;
    width: 60px;
    height: 60px;
  }
  .content-spinner {
    margin: 100% 45%;
    width: 60px;
    height: 60px;
  }
  #suggestProduct {
    position: absolute;
    display: none;
    outline: 0;
    border-radius: 5;
    z-index: -1;
    width: 100%;
  }

  #list {
    list-style: none;
    margin-top: 8.5rem;
    margin-left: 0em;
    width: 25rem;
    padding-bottom: 1.5em;
    background-color: white;
    border-top: 1px solid lightgray;
    max-height: 0;
    overflow: hidden;
    overflow-y: auto;
    animation: enlarge 0.25s forwards;
    li {
      margin-right: 0em;
      margin-left: 0em;
      padding: 0em;
      line-height: 2;
      cursor: pointer;
      &:hover {
        background: whitesmoke;
      }
    }
  }

  @keyframes enlarge {
    to {
      max-height: 30em;
    }
  }
}

//order tracking

@import url("https://cdn.jsdelivr.net/npm/pixeden-stroke-7-icon@1.2.3/pe-icon-7-stroke/dist/pe-icon-7-stroke.min.css");

.steps .step {
  display: block;
  width: 100%;
  margin-bottom: 35px;
  text-align: center;
}
.steps .step .step-icon-wrap {
  display: block;
  position: relative;
  width: 100%;
  height: 80px;
  text-align: center;
}
.steps .step .step-icon-wrap::before,
.steps .step .step-icon-wrap::after {
  display: block;
  position: absolute;
  top: 50%;
  width: 50%;
  height: 3px;
  margin-top: -1px;
  background-color: #e1e7ec;
  content: "";
  z-index: 1;
}
.steps .step .step-icon-wrap::before {
  left: 0;
}
.steps .step .step-icon-wrap::after {
  right: 0;
}
.steps .step .step-icon {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
  border: 1px solid #e1e7ec;
  border-radius: 50%;
  background-color: #f5f5f5;
  color: #374250;
  font-size: 38px;
  line-height: 81px;
  z-index: 5;
}
.steps .step .step-title {
  margin-top: 16px;
  margin-bottom: 0;
  color: #606975;
  font-size: 14px;
  font-weight: 500;
}
.steps .step:first-child .step-icon-wrap::before {
  display: none;
}
.steps .step:last-child .step-icon-wrap::after {
  display: none;
}
.steps .step.completed .step-icon-wrap::before,
.steps .step.completed .step-icon-wrap::after {
  background-color: #0da9ef;
}
.steps .step.completed .step-icon {
  border-color: #0da9ef;
  background-color: #0da9ef;
  color: #fff;
}
@media (max-width: 576px) {
  .flex-sm-nowrap .step .step-icon-wrap::before,
  .flex-sm-nowrap .step .step-icon-wrap::after {
    display: none;
  }
}
@media (max-width: 768px) {
  .flex-md-nowrap .step .step-icon-wrap::before,
  .flex-md-nowrap .step .step-icon-wrap::after {
    display: none;
  }
}
@media (max-width: 991px) {
  .flex-lg-nowrap .step .step-icon-wrap::before,
  .flex-lg-nowrap .step .step-icon-wrap::after {
    display: none;
  }
}
@media (max-width: 1200px) {
  .flex-xl-nowrap .step .step-icon-wrap::before,
  .flex-xl-nowrap .step .step-icon-wrap::after {
    display: none;
  }
}
.bg-faded,
.bg-secondary {
  background-color: #f5f5f5 !important;
}

// notification color
// .Toastify__toast-container {
//   width: 320px;
// }
// .Toastify__toast--default {
//     background: #fff;
//     color: #aaa;
//  }
//   .Toastify__toast--info {
//     background: #3498db;
//  }
//   .Toastify__toast--success {
//     background: #07bc0c;
//  }
//   .Toastify__toast--warning {
//     background: #f1c40f;
//  }
//   .Toastify__toast--error {
//     background: #e74c3c;
//  }

.payment-method {
  padding: 0px;
  margin: 0px 20px;
}

.payment-method li {
  display: inline;
  list-style-type: none;
}

.payment-method img {
  width: 45px;
  height: 45px;
  margin: 0px 2px;
}

.nagad-img {
  width: 80px !important;
  height: 45px;
}

.bkash-img {
  width: 80px !important;
  height: 45px;
}

// out of stock

.note {
  position: absolute;
  top: 0;
  left: 0;
  padding: 1px 9px;
  font-size: 0.9em;
}

.note.no-stock {
  background: #e00a0a;
  color: #fff;
}

.no-product-category {
  margin: 0 20rem !important;
}

// free delivery
.animate-charcter {
  text-transform: uppercase;
  background-image: linear-gradient(
    -225deg,
    #239dd1 0%,
    #ff1361 29%,
    #f46a6a 67%,
    #1dd871 100%
  );
  background-size: auto auto;
  background-clip: border-box;
  background-size: 200% auto;
  color: #fff;
  background-clip: text;
  text-fill-color: transparent;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  animation: textclip 3s linear infinite;
  display: inline-block;
  font-size: 1rem;
}

@keyframes textclip {
  to {
    background-position: 200% center;
  }
}

.free-delivery {
  text-transform: uppercase;
  background-image: linear-gradient(
    -225deg,
    #17b396 0%,
    #142d78 50%,
    #9d1839 67%,
    #b64860 100%
  );
  background-size: auto auto;
  background-clip: border-box;
  background-size: 200% auto;
  color: #fff;
  background-clip: text;
  text-fill-color: transparent;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  animation: textclip 3s linear infinite;
  display: inline-block;
  font-size: 0.7rem;
  font-weight: bold;
}

@keyframes textclip {
  to {
    background-position: 200% center;
  }
}


// stamp style for delivery free

// .stamp {
//   // transform: rotate(12deg);
//   color: #555;
//   font-size: .75rem;
//   font-weight: 700;
//   // border: 0.25rem solid #555;
//   display: inline-block;
//   // padding: 0.25rem 1rem;
//   text-transform: uppercase;
//   // border-radius: 1rem;
//   font-family: "Courier";
//   // -webkit-mask-image: url("https://s3-us-west-2.amazonaws.com/s.cdpn.io/8399/grunge.png");
//   // -webkit-mask-size: 944px 604px;
//   mix-blend-mode: multiply;

// }
// .is-approved {
//   background-color: #239DD1;
//   color: #ffffff;
//   // border: 0.15rem solid #239DD1;
//   // -webkit-mask-position: 13rem 6rem;
//   transform: rotate(0deg);
//   border-radius: 0;
// }


.free {
  position: absolute;
  top: 0;
  right: 0;
  padding: 1px 9px;
  font-size: 0.9em;
}

.delivery {
  background: #ffffff32;
  color: #fff;
}